import ChatIcon from "@mui/icons-material/Chat";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

import React, { useEffect, useState } from "react";

import { useUserAuth } from "../contexts/authContext";
import { useEnv } from "../contexts/envContext";
import { useMap } from "../contexts/mapContext";
import { useSocket } from "../contexts/socketContext";
import { getOperationEditable, getOperationPriority } from "../manager/operations/opUtil";
import { ConvertMetersToFeet, GetColorFromState } from "../util";
import { ListItemText } from "@mui/material";

import * as CONSTANT from "../manager/operations/opConstants";

export const MapFlightCards = (props) => {
    const [operationDetailsVisible, setOperationDetailsVisible] = useState(new Map());
    const [operations, setOperations] = useState([]);
    const [filters, setFilters] = useState(["Most Recent"]);

    const { user, organizations, userOperationalStates, handleFailedFetch } = useUserAuth();
    const { conversations, createConversation, setSelectedConversationUUID } = useSocket();
    const { drones } = useMap();
    const { laancOn } = useEnv();

    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        setSelectedConversationUUID(null);
    }, []);

    useEffect(() => {
        let ops = [...props.operations];
        if (filters.includes("Most Recent")) {
            ops.sort((a, b) => new Date(a.time_start) - new Date(b.time_start));
        }
        if (filters.includes("Today's Flights")) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            ops = ops.filter((op) => {
                const startDay = new Date(op.time_start);
                const endDay = new Date(op.time_end);
                startDay.setHours(0, 0, 0, 0);
                endDay.setHours(0, 0, 0, 0);
                return today.getTime() >= startDay.getTime() && today.getTime() <= endDay.getTime();
            });
        }
        if (filters.includes("Active Flights")) {
            ops = ops.filter((op) => op.state === "ACTIVATED" || op.state === "CONTINGENT" || op.state === "NONCONFORMING");
        }
        if (filters.includes("Own Organization")) {
            ops = ops.filter((op) => op.organization_id === user.organization_id);
        }

        const opDetails = new Map();
        ops.forEach((op) => {
            const value = operationDetailsVisible.get(op.flight_uuid) || false;
            opDetails.set(op.flight_uuid, value);
        });

        setOperations(ops);
        setOperationDetailsVisible(opDetails);
    }, [props.operations, filters]);

    const handleWatchButtonClicked = (operation) => {
        const entityId = operation.otd_uuid;
        props.handleWatchEntity(entityId);
    };

    const handleChatButtonClicked = (id, email) => {
        const conversation = conversations.find(({ recipients }) => recipients.includes(id) && recipients.includes(user.id) && recipients.length === 2);
        if (conversation) {
            setSelectedConversationUUID(conversation.conversation_uuid);
        } else {
            createConversation([id, user.id], [email, user.email]);
        }
        props.setConversationDialogOpen(true);
    };

    const handleChangeOperationStatus = (e, op) => {
        if (!confirm("Are you sure you want to modify this operation?")) {
            return;
        }
        let operation = { ...op };
        operation.state = e.target.value;
        operation.updated_user_id = user.id;
        operation.version = operation.version + 1;

        let url = "";
        if (e.target.value === "ENDED") {
            url = "/api/op/delete";
        } else {
            url = "/api/op/updatePublish";
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch(url, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleEditButtonClicked = (operation) => {
        props.setEditFlightDialogOpen(true);
        props.setEditFlightVolume(operation);
    };

    const handleDetailsButtonClicked = (operation) => {
        props.setFlightDetailsDialogOpen(true);
        props.setFlightDetailsVolume(operation);
    };

    const handleToggleOperationDetails = ({ flight_uuid }) => {
        const newOperationDetailsVisible = new Map();
        operationDetailsVisible.forEach((value, key) => {
            if (key === flight_uuid) {
                newOperationDetailsVisible.set(key, !value);
            } else newOperationDetailsVisible.set(key, false);
        });
        setOperationDetailsVisible(newOperationDetailsVisible);
    };

    return (
        <>
            <Card
                sx={{ m: "4px", p: "4px", overflow: "unset", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "1px", height: "60px" }}
            >
                <IconButton onClick={() => props.setFlightCardsOpen(false)}>
                    <ChevronRightIcon />
                </IconButton>
                <Typography variant="h6" color="text.primary" fontWeight="normal">
                    Filters:
                </Typography>
                <FormControl sx={{ ml: "auto", mr: "10px" }} size="small">
                    <Select
                        multiple
                        displayEmpty
                        value={filters}
                        onChange={(e) => setFilters(e.target.value)}
                        renderValue={(filters) => (
                            <Typography variant="body1" color="text.secondary" sx={{ justifyContent: "center", alignItems: "center" }}>
                                {filters.length} active filter{filters.length !== 1 && "s"}
                            </Typography>
                        )}
                        SelectDisplayProps={{ "data-testid": "filters" }}
                        sx={{ width: "160px", maxHeight: "40px" }}
                    >
                        <MenuItem value="Most Recent" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Most Recent")} />
                            <ListItemText primary="Most Recent" />
                        </MenuItem>
                        <MenuItem value="Today's Flights" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Today's Flights")} />
                            <ListItemText primary="Today's Flights" />
                        </MenuItem>
                        <MenuItem value="Active Flights" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Active Flights")} />
                            <ListItemText primary="Active Flights" />
                        </MenuItem>
                        <MenuItem value="Own Organization" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Own Organization")} />
                            <ListItemText primary="Own Organization" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </Card>

            {operations.map((operation) => {
                const expanded = operationDetailsVisible.get(operation.flight_uuid);
                const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
                const isOwnOp = user.id === operation.created_user_id ? true : false;

                const editable = getOperationEditable(operation, user);
                const priority = getOperationPriority(operation);
                const minAlt = ConvertMetersToFeet(operation.volumes[0].altitude_min_agl_m);
                const maxAlt = ConvertMetersToFeet(operation.volumes[0].altitude_max_agl_m);
                const org = organizations?.filter((org) => org.id === operation.organization_id)[0];
                const orgName = org ? org.name : "Unknown";

                const faa_required = operation.faa_approval_required ? true : false;
                const laancState = Object.prototype.hasOwnProperty.call(operation, "laanc") ? operation.laanc.state.replace(/_/g, " ") : "NOT REQUIRED";

                const entityVisible = props.handleCheckFlightASDVisible(operation.otd_uuid);

                const alertStatusRef = props.flightCardAlertStatusRefs.get(operation.flight_uuid);
                if (alertStatusRef !== undefined) {
                    if (!entityVisible && alertStatusRef.current !== null && alertStatusRef.current !== undefined) {
                        alertStatusRef.current.children[0].innerHTML = "--";
                        alertStatusRef.current.children[0].style.color = "#6b778c";
                        alertStatusRef.current.children[1].innerHTML = "--";
                        alertStatusRef.current.children[1].style.color = "#6b778c";
                    }
                }
                const vehicle = drones.find((drone) => drone.vehicle_uuid === operation.vehicle_uuid);
                const start = new Date(operation.time_start).toLocaleString("en-US", options);
                const end = new Date(operation.time_end).toLocaleString("en-US", options);

                const pilot =
                    operation.pilot_name && operation.pilot_name.trim() ? operation.pilot_name : operation.pilot_email ? operation.pilot_email : "Unknown";

                const canChat =
                    operation.created_user_id !== user.id &&
                    (operation.organization_id === user.organization_id || user.user_role_id === 4) &&
                    pilot !== "Unknown";

                const isConforming = operation.state !== "CONTINGENT" && operation.state !== "NONCONFORMING";

                return (
                    <Card key={operation.flight_uuid} sx={{ m: "4px", overflow: "unset" }}>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "250px", mr: "auto" }}
                                    >
                                        {operation.name}
                                    </Typography>

                                    {entityVisible ? (
                                        <Tooltip title="Monitor">
                                            <IconButton aria-label="settings" onClick={() => handleWatchButtonClicked(operation)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <></>
                                    )}
                                    <Tooltip title="View">
                                        <IconButton aria-label="View" onClick={() => props.handleJumpToOperation(operation.flight_uuid)}>
                                            <TravelExploreIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            subheader={`${start} - ${end}`}
                            sx={{ pb: 1, borderBottom: 1 }}
                        />

                        <CardContent sx={{ py: 1, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 1 }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Vehicle: </b>
                                <Tooltip title={vehicle ? vehicle.name : "Unknown"}>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whitespace: "nowrap"
                                        }}
                                        id={`vehicle-${operation.volumeId}`}
                                    >
                                        {vehicle ? vehicle.name : "Unknown"}
                                    </span>
                                </Tooltip>
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Operator:</b>
                                <Tooltip title={pilot}>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whitespace: "nowrap"
                                        }}
                                        id={`operator-${operation.volumeId}`}
                                    >
                                        {canChat ? (
                                            <IconButton
                                                sx={{ fontSize: "small", height: "20px", overflowY: "visible" }}
                                                onClick={() => handleChatButtonClicked(operation.created_user_id, operation.pilot_email)}
                                            >
                                                <ChatIcon fontSize="inherit" />
                                            </IconButton>
                                        ) : (
                                            <></>
                                        )}
                                        {pilot}
                                    </span>
                                </Tooltip>
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Airspace Status: </b>
                                {operation.conflicted ? (
                                    <Tooltip title="CONFLICTED">
                                        <span
                                            style={{ color: "#CB4C4E", overflow: "hidden", textOverflow: "ellipsis", whitespace: "nowrap" }}
                                            id={`airspaceStatus-${operation.volumeId}`}
                                        >
                                            CONFLICTED
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="CLEAR">
                                        <span
                                            style={{ color: "#5fa052", overflow: "hidden", textOverflow: "ellipsis", whitespace: "nowrap" }}
                                            id={`airspaceStatus-${operation.volumeId}`}
                                        >
                                            CLEAR
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Alerts: </b>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whitespace: "nowrap"
                                    }}
                                    ref={alertStatusRef}
                                >
                                    <span>--</span>
                                    <span></span>
                                </span>
                            </Typography>
                        </CardContent>

                        <CardActions sx={{ gap: 1 }}>
                            <FormControl size="small" sx={{ minWidth: "125px" }} disabled={!editable}>
                                <InputLabel id="select-label-status">Op Status</InputLabel>
                                <Select
                                    label="Op Status"
                                    labelId="select-label-status"
                                    value={operation.state}
                                    onChange={(e) => handleChangeOperationStatus(e, operation)}
                                    sx={{ color: GetColorFromState(operation.state, userOperationalStates) }}
                                    inputProps={{ "data-testid": "selectStatus" }}
                                >
                                    <MenuItem value={"ACCEPTED"}>ACCEPTED</MenuItem>
                                    <MenuItem value={"ACTIVATED"}>ACTIVATED</MenuItem>
                                    <MenuItem value={"ENDED"}>ENDED</MenuItem>
                                    <MenuItem value={"CONTINGENT"}>CONTINGENT</MenuItem>
                                    <MenuItem value={"NONCONFORMING"}>NONCONFORMING</MenuItem>
                                </Select>
                            </FormControl>
                            {editable === true && faa_required === false && isConforming === true && isDesktop === true ? (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleEditButtonClicked(operation)}
                                    data-testid="edit"
                                    id={`edit-${operation.volumeId}`}
                                >
                                    Edit
                                </Button>
                            ) : (
                                <></>
                            )}
                            {laancOn === "true" && isOwnOp && faa_required ? (
                                operation.laanc.state === CONSTANT.LAANC_RESCINDED_AWAITING || operation.laanc.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                                    <Badge badgeContent={"!"} color={"error"}>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleDetailsButtonClicked(operation)}
                                            sx={{ ml: "0 !important" }}
                                            data-testid="laanc"
                                        >
                                            LAANC
                                        </Button>
                                    </Badge>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleDetailsButtonClicked(operation)}
                                        sx={{ ml: "0 !important" }}
                                        data-testid="laanc"
                                    >
                                        LAANC
                                    </Button>
                                )
                            ) : (
                                <></>
                            )}
                            <IconButton onClick={() => handleToggleOperationDetails(operation)} sx={{ ml: "auto !important" }}>
                                {expanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </CardActions>

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent sx={{ borderTop: 1, display: "grid", gridTemplateColumns: "1fr", gap: 1 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>Op UUID: </b>
                                    <span>{operation.flight_uuid}</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>Priority: </b>
                                    <span>{priority}</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>Version: </b>
                                    <span>{operation.version}</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>Min Altitude (AGL): </b>
                                    <span>{minAlt}ft</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>Max Altitude (AGL): </b>
                                    <span>{maxAlt}ft</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>Organization: </b>
                                    <span>{orgName}</span>
                                </Typography>
                                {laancOn === "true" ? (
                                    <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <b>LAANC State: </b>
                                        <span>{laancState}</span>
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </CardContent>
                        </Collapse>
                    </Card>
                );
            })}
        </>
    );
};
