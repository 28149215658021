import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import { v4 as uuid_v4 } from "uuid";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";

function AddToolbar(props) {
    const { setEditRow, setZcaDialogOpen } = props;
    const { user } = useUserAuth();

    const handleClick = () => {
        setEditRow({
            id: 0,
            config_uuid: uuid_v4(),
            organization_id: user.organization_id,
            name: "",
            org_default: false,
            min_alert_time_sec: 8,
            ownship_expire_sec: 10,
            surveillance_expire_sec: 5,
            secondary_shutoff_sec: 600,
            lat_bound_range_deg: 1.0,
            lon_bound_range_deg: 1.0,
            alt_bound_range_m: 3048.0,
            m_of_n: [2, 4],
            alert_volumes_warning_radar_horz_m: 2153.4,
            alert_volumes_warning_radar_vert_m: 135.6,
            alert_volumes_warning_adsb_horz_m: 2153.4,
            alert_volumes_warning_adsb_vert_m: 135.6,
            alert_volumes_warning_uas_horz_m: 2153.4,
            alert_volumes_warning_uas_vert_m: 135.6,
            alert_volumes_warning_projection_sec: 0,
            alert_volumes_caution_radar_horz_m: 2829.5,
            alert_volumes_caution_radar_vert_m: 198.1,
            alert_volumes_caution_adsb_horz_m: 2829.5,
            alert_volumes_caution_adsb_vert_m: 198.1,
            alert_volumes_caution_uas_horz_m: 2829.5,
            alert_volumes_caution_uas_vert_m: 198.1,
            alert_volumes_caution_projection_sec: 0
        });
        setZcaDialogOpen(true);
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Record
            </Button>
        </GridToolbarContainer>
    );
}

export function ZCASettings() {
    const { handleFailedFetch, getOrganizationByID } = useUserAuth();
    const [configs, setConfigs] = useState([]);
    const [editRow, setEditRow] = useState({});
    const [zcaDialogOpen, setZcaDialogOpen] = useState(false);
    const isDesktop = useMediaQuery("(min-width:900px)");

    const zcaColumns = [
        {
            field: "org_default",
            headerName: "Default",
            width: 80,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            hide: !isDesktop,
            renderCell: (cellValues) => {
                return cellValues.formattedValue ? (
                    <Tooltip title="Default">
                        <CheckCircleIcon color="success" fontSize="small" />
                    </Tooltip>
                ) : (
                    <Tooltip title="">
                        <RadioButtonUncheckedIcon color="disabled" fontSize="small" />
                    </Tooltip>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: 100,
            flex: 1,
            editable: false
        },
        {
            field: "config_uuid",
            headerName: "UUID",
            width: 100,
            flex: 1,
            editable: false,
            hide: !isDesktop
        },
        {
            field: "organization_id",
            headerName: "Org",
            width: 100,
            flex: 1,
            editable: false,
            valueGetter: (value) => {
                const org = getOrganizationByID(value);
                return org ? org.name : "";
            }
        },
        {
            field: "date_created",
            headerName: "Date Created",
            width: 100,
            flex: 1,
            editable: false,
            hide: !isDesktop,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "date_updated",
            headerName: "Date Updated",
            width: 100,
            flex: 1,
            editable: false,
            hide: !isDesktop,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        key={params.id}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(params.row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={params.id}
                        icon={<DeleteIcon />}
                        label="Delete"
                        className="textPrimary"
                        onClick={() => handleDeleteClick(params.row)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    useEffect(() => {
        fetchData();
        return () => setConfigs([]);
    }, []);

    const fetchData = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("/api/zca_configs/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setConfigs(data))
            .catch((err) => handleFailedFetch(err));
    };

    const handleEditClick = (row) => {
        setEditRow(row);
        setZcaDialogOpen(true);
    };

    const handleDeleteClick = (row) => {
        if (!confirm("Are you sure you want to Delete this Configuration?")) {
            return;
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        };
        fetch(`/api/zca_configs/delete/${row.id}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => fetchData())
            .catch((err) => handleFailedFetch(err));
        alert("Advise: These changes will not impact active ZCA sessions.");
    };

    const handleZcaDialogClose = () => {
        setZcaDialogOpen(false);
        fetchData();
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", pt: 3, px: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Manage ZCA Configs
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", width: "100%" }}>
                <DataGrid
                    rows={configs}
                    columns={zcaColumns}
                    getRowId={(row) => row.id}
                    autoHeight
                    disableRowSeletionOnClick
                    disableVirtualization
                    slots={{ toolbar: AddToolbar, pagination: CustomPagination }}
                    slotProps={{ toolbar: { setEditRow, setZcaDialogOpen } }}
                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                />
                {zcaDialogOpen ? (
                    <ZCADialog configs={configs} row={editRow} zcaDialogOpen={zcaDialogOpen} handleZcaDialogClose={handleZcaDialogClose} />
                ) : (
                    <></>
                )}
            </Paper>
        </Grid>
    );
}

export function ZCADialog(props) {
    const { user, getOrganizationByID, handleFailedFetch, snackbar, setSnackbar } = useUserAuth();
    const isDesktop = useMediaQuery("(min-width:900px)");

    const row = props.row;
    const [uuid] = useState(row.config_uuid);
    const [organizationId] = useState(row.organization_id);
    const [name, setName] = useState(row.name);
    const [orgDefault, setOrgDefault] = useState(row.org_default);
    const [organization] = useState(getOrganizationByID(organizationId));
    const [minAlertTime, setMinAlertTime] = useState(row.min_alert_time_sec);
    const [ownshipExpire, setOwnshipExpire] = useState(row.ownship_expire_sec);
    const [surveillanceExpire, setSurveillanceExpire] = useState(row.surveillance_expire_sec);
    const [secondaryShutoff, setSecondaryShutoff] = useState(row.secondary_shutoff_sec);
    const [latBoundRange, setLatBoundRange] = useState(row.lat_bound_range_deg);
    const [lonBoundRange, setLonBoundRange] = useState(row.lon_bound_range_deg);
    const [altBoundRange, setAltBoundRange] = useState(row.alt_bound_range_m);
    const [noiseFilterM, setNoiseFilterM] = useState(row.m_of_n[0]);
    const [noiseFilterN, setNoiseFilterN] = useState(row.m_of_n[1]);
    const [warningRadarHorz, setWarningRadarHorz] = useState(row.alert_volumes_warning_radar_horz_m);
    const [warningRadarVert, setWarningRadarVert] = useState(row.alert_volumes_warning_radar_vert_m);
    const [warningAdsbHorz, setWarningAdsbHorz] = useState(row.alert_volumes_warning_adsb_horz_m);
    const [warningAdsbVert, setWarningAdsbVert] = useState(row.alert_volumes_warning_adsb_vert_m);
    const [warningUasHorz, setWarningUasHorz] = useState(row.alert_volumes_warning_uas_horz_m);
    const [warningUasVert, setWarningUasVert] = useState(row.alert_volumes_warning_uas_vert_m);
    const [warningProjection, setWarningProjection] = useState(row.alert_volumes_warning_projection_sec);
    const [cautionRadarHorz, setCautionRadarHorz] = useState(row.alert_volumes_caution_radar_horz_m);
    const [cautionRadarVert, setCautionRadarVert] = useState(row.alert_volumes_caution_radar_vert_m);
    const [cautionAdsbHorz, setCautionAdsbHorz] = useState(row.alert_volumes_caution_adsb_horz_m);
    const [cautionAdsbVert, setCautionAdsbVert] = useState(row.alert_volumes_caution_adsb_vert_m);
    const [cautionUasHorz, setCautionUasHorz] = useState(row.alert_volumes_caution_uas_horz_m);
    const [cautionUasVert, setCautionUasVert] = useState(row.alert_volumes_caution_uas_vert_m);
    const [cautionProjection, setCautionProjection] = useState(row.alert_volumes_caution_projection_sec);

    const handleIntegerFieldChange = (e, setField) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setField(value);
        }
    };

    const handleSave = async () => {
        const validateNumericalFields = (...fields) => {
            return fields.every((field) => {
                const value = parseFloat(field);
                return !isNaN(value) && value >= 0;
            });
        };
        const validateAlertFields = (...alertFields) => {
            for (let i = 0; i + 1 < alertFields.length; i += 2) {
                const warning = parseFloat(alertFields[i]);
                const caution = parseFloat(alertFields[i + 1]);
                if (warning > caution) {
                    return false;
                }
            }
            return true;
        };

        if (!name) {
            return setSnackbar({ children: "Please fill in the name of the configuration.", severity: "error" });
        } else if (
            orgDefault &&
            props.configs.some((config) => config.config_uuid !== uuid && config.organization_id === organizationId && config.org_default)
        ) {
            return setSnackbar({ children: "A default configuration is already set for this organization.", severity: "error" });
        } else if (
            !validateNumericalFields(
                minAlertTime,
                ownshipExpire,
                surveillanceExpire,
                secondaryShutoff,
                altBoundRange,
                noiseFilterM,
                noiseFilterN,
                warningRadarHorz,
                warningRadarVert,
                warningAdsbHorz,
                warningAdsbVert,
                warningUasHorz,
                warningUasVert,
                warningProjection,
                cautionRadarHorz,
                cautionRadarVert,
                cautionAdsbHorz,
                cautionAdsbVert,
                cautionUasHorz,
                cautionUasVert,
                cautionProjection
            )
        ) {
            return setSnackbar({ children: "Please enter valid (non-negative) values for the below fields.", severity: "error" });
        } else if (
            !validateAlertFields(
                warningRadarHorz,
                cautionRadarHorz,
                warningRadarVert,
                cautionRadarVert,
                warningAdsbHorz,
                cautionAdsbHorz,
                warningAdsbVert,
                cautionAdsbVert,
                warningUasHorz,
                cautionUasHorz,
                warningUasVert,
                cautionUasVert
            )
        ) {
            return setSnackbar({ children: "All warning fields must be less than (or equal) to its corresponding caution field.", severity: "error" });
        } else if (parseFloat(noiseFilterM) > parseFloat(noiseFilterN)) {
            return setSnackbar({ children: "Noise filter M must be less than (or equal) to noise filter N.", severity: "error" });
        } else if (
            isNaN(parseFloat(latBoundRange)) ||
            parseFloat(latBoundRange) < -90 ||
            parseFloat(latBoundRange) > 90 ||
            isNaN(parseFloat(lonBoundRange)) ||
            parseFloat(lonBoundRange) < -180 ||
            parseFloat(lonBoundRange) > 180
        ) {
            return setSnackbar({ children: "Latitude must be [-90, 90]. Longitude must be [-180, 180].", severity: "error" });
        }

        const newRow = {
            id: row.id,
            config_uuid: uuid,
            organization_id: organizationId,
            name: name,
            org_default: orgDefault,
            min_alert_time_sec: minAlertTime,
            ownship_expire_sec: ownshipExpire,
            surveillance_expire_sec: surveillanceExpire,
            secondary_shutoff_sec: secondaryShutoff,
            lat_bound_range_deg: latBoundRange,
            lon_bound_range_deg: lonBoundRange,
            alt_bound_range_m: altBoundRange,
            m_of_n: [noiseFilterM, noiseFilterN],
            alert_volumes_warning_radar_horz_m: warningRadarHorz,
            alert_volumes_warning_radar_vert_m: warningRadarVert,
            alert_volumes_warning_adsb_horz_m: warningAdsbHorz,
            alert_volumes_warning_adsb_vert_m: warningAdsbVert,
            alert_volumes_warning_uas_horz_m: warningUasHorz,
            alert_volumes_warning_uas_vert_m: warningUasVert,
            alert_volumes_warning_projection_sec: warningProjection,
            alert_volumes_caution_radar_horz_m: cautionRadarHorz,
            alert_volumes_caution_radar_vert_m: cautionRadarVert,
            alert_volumes_caution_adsb_horz_m: cautionAdsbHorz,
            alert_volumes_caution_adsb_vert_m: cautionAdsbVert,
            alert_volumes_caution_uas_horz_m: cautionUasHorz,
            alert_volumes_caution_uas_vert_m: cautionUasVert,
            alert_volumes_caution_projection_sec: cautionProjection,
            updated_user_id: user.id
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newRow)
        };
        await fetch("/api/zca_configs/insertupdate", requestOptions).catch((err) => handleFailedFetch(err));

        handleClose();
        if (row.id !== 0) {
            alert("Advise: These changes will not impact active ZCA sessions.");
        }
    };

    const handleClose = () => {
        props.handleZcaDialogClose();
    };

    return (
        <Dialog onClose={handleClose} open={props.zcaDialogOpen} maxWidth={isDesktop ? "lg" : "xs"} fullWidth>
            <DialogTitle>{row.id === 0 ? "Create" : "Edit"} Configuration</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1.35fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="uuid"
                            inputProps={{ "data-testid": "uuid" }}
                            label="UUID"
                            value={uuid}
                            disabled
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                        />
                        <TextField
                            id="organization"
                            inputProps={{ "data-testid": "organization" }}
                            label="Organization"
                            value={organization ? organization.name : "Unknown"}
                            disabled
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                        />
                        <TextField
                            id="name"
                            inputProps={{ "data-testid": "name" }}
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={snackbar && !name}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="defaultConfiguration"
                                    inputProps={{ "data-testid": "defaultConfiguration" }}
                                    checked={orgDefault}
                                    onChange={() => setOrgDefault(!orgDefault)}
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                                />
                            }
                            label={<Typography sx={{ fontSize: 15 }}>Default Configuration for Org?</Typography>}
                            sx={{ mx: 0 }}
                        />
                    </Box>
                    <Divider textAlign="left" flexItem>
                        Settings
                    </Divider>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="latBoundRange"
                            inputProps={{ "data-testid": "latBoundRange" }}
                            label="Latitude Bound Range"
                            value={latBoundRange}
                            onChange={(e) => setLatBoundRange(e.target.value)}
                            error={snackbar && (isNaN(parseFloat(latBoundRange)) || parseFloat(latBoundRange) < -90 || parseFloat(latBoundRange) > 90)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                        />
                        <TextField
                            id="lonBoundRange"
                            inputProps={{ "data-testid": "lonBoundRange" }}
                            label="Longitude Bound Range"
                            value={lonBoundRange}
                            onChange={(e) => setLonBoundRange(e.target.value)}
                            error={snackbar && (isNaN(parseFloat(lonBoundRange)) || parseFloat(lonBoundRange) < -180 || parseFloat(lonBoundRange) > 180)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                        />
                        <TextField
                            id="altBoundRange"
                            inputProps={{ "data-testid": "altBoundRange" }}
                            label="Altitude Bound Range"
                            value={altBoundRange}
                            onChange={(e) => setAltBoundRange(e.target.value)}
                            error={snackbar && (isNaN(parseFloat(altBoundRange)) || parseFloat(altBoundRange) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="noiseFilterM"
                            inputProps={{ "data-testid": "noiseFilterM" }}
                            label="Noise Filter M"
                            value={noiseFilterM}
                            onChange={(e) => handleIntegerFieldChange(e, setNoiseFilterM)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(noiseFilterM)) || parseFloat(noiseFilterM) < 0 || parseFloat(noiseFilterM) > parseFloat(noiseFilterN))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                        />
                        <TextField
                            id="noiseFilterN"
                            inputProps={{ "data-testid": "noiseFilterN" }}
                            label="Noise Filter N"
                            value={noiseFilterN}
                            onChange={(e) => handleIntegerFieldChange(e, setNoiseFilterN)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(noiseFilterN)) || parseFloat(noiseFilterN < 0) || parseFloat(noiseFilterM) > parseFloat(noiseFilterN))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                        />
                    </Box>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="minAlertTime"
                            inputProps={{ "data-testid": "minAlertTime" }}
                            label="Minimum Alert Time"
                            value={minAlertTime}
                            onChange={(e) => handleIntegerFieldChange(e, setMinAlertTime)}
                            error={snackbar && (isNaN(parseFloat(minAlertTime)) || parseFloat(minAlertTime) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
                        />
                        <TextField
                            id="ownshipExpireTime"
                            inputProps={{ "data-testid": "ownshipExpireTime" }}
                            label="Ownship Expire Time"
                            value={ownshipExpire}
                            onChange={(e) => handleIntegerFieldChange(e, setOwnshipExpire)}
                            error={snackbar && (isNaN(parseFloat(ownshipExpire)) || parseFloat(ownshipExpire) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
                        />
                        <TextField
                            id="surveillanceExpireTime"
                            inputProps={{ "data-testid": "surveillanceExpireTime" }}
                            label="Surveillance Expire Time"
                            value={surveillanceExpire}
                            onChange={(e) => handleIntegerFieldChange(e, setSurveillanceExpire)}
                            error={snackbar && (isNaN(parseFloat(surveillanceExpire)) || parseFloat(surveillanceExpire) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
                        />
                        <TextField
                            id="secondaryShutoffTime"
                            inputProps={{ "data-testid": "secondaryShutoffTime" }}
                            label="Secondary Shutoff Time"
                            value={secondaryShutoff}
                            onChange={(e) => handleIntegerFieldChange(e, setSecondaryShutoff)}
                            error={snackbar && (isNaN(parseFloat(secondaryShutoff)) || parseFloat(secondaryShutoff) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
                        />
                    </Box>
                    <Divider textAlign="left" flexItem>
                        Warning Alert Params <WarningAmberIcon sx={{ color: "#B96F0B" }} />
                    </Divider>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="warningRadarHorz"
                            inputProps={{ "data-testid": "warningRadarHorz" }}
                            label="Radar Horizontal"
                            value={warningRadarHorz}
                            onChange={(e) => setWarningRadarHorz(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(warningRadarHorz)) ||
                                    parseFloat(warningRadarHorz) < 0 ||
                                    parseFloat(warningRadarHorz) > parseFloat(cautionRadarHorz))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="warningRadarVert"
                            inputProps={{ "data-testid": "warningRadarVert" }}
                            label="Radar Vertical"
                            value={warningRadarVert}
                            onChange={(e) => setWarningRadarVert(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(warningRadarVert)) ||
                                    parseFloat(warningRadarVert) < 0 ||
                                    parseFloat(warningRadarVert) > parseFloat(cautionRadarVert))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="warningAdsbHorz"
                            inputProps={{ "data-testid": "warningAdsbHorz" }}
                            label="ADSB Horizontal"
                            value={warningAdsbHorz}
                            onChange={(e) => setWarningAdsbHorz(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(warningAdsbHorz)) ||
                                    parseFloat(warningAdsbHorz) < 0 ||
                                    parseFloat(warningAdsbHorz) > parseFloat(cautionAdsbHorz))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="warningAdsbVert"
                            inputProps={{ "data-testid": "warningAdsbVert" }}
                            label="ADSB Vertical"
                            value={warningAdsbVert}
                            onChange={(e) => setWarningAdsbVert(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(warningAdsbVert)) ||
                                    parseFloat(warningAdsbVert) < 0 ||
                                    parseFloat(warningAdsbVert) > parseFloat(cautionAdsbVert))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                    </Box>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="warningUasHorz"
                            inputProps={{ "data-testid": "warningUasHorz" }}
                            label="UAS Horizontal"
                            value={warningUasHorz}
                            onChange={(e) => setWarningUasHorz(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(warningUasHorz)) || parseFloat(warningUasHorz) < 0 || parseFloat(warningUasHorz) > parseFloat(cautionUasHorz))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="warningUasVert"
                            inputProps={{ "data-testid": "warningUasVert" }}
                            label="UAS Vertical"
                            value={warningUasVert}
                            onChange={(e) => setWarningUasVert(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(warningUasVert)) || parseFloat(warningUasVert) < 0 || parseFloat(warningUasVert) > parseFloat(cautionUasVert))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="warningProjection"
                            inputProps={{ "data-testid": "warningProjection" }}
                            label="Projection"
                            value={warningProjection}
                            onChange={(e) => handleIntegerFieldChange(e, setWarningProjection)}
                            error={snackbar && (isNaN(parseFloat(warningProjection)) || parseFloat(warningProjection) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
                        />
                    </Box>
                    <Divider textAlign="left" flexItem>
                        Caution Alert Params <WarningAmberIcon sx={{ color: "#BEBB1F" }} />
                    </Divider>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="cautionRadarHorz"
                            inputProps={{ "data-testid": "cautionRadarHorz" }}
                            label="Radar Horizontal"
                            value={cautionRadarHorz}
                            onChange={(e) => setCautionRadarHorz(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(cautionRadarHorz)) ||
                                    parseFloat(cautionRadarHorz) < 0 ||
                                    parseFloat(warningRadarHorz) > parseFloat(cautionRadarHorz))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="cautionRadarVert"
                            inputProps={{ "data-testid": "cautionRadarVert" }}
                            label="Radar Vertical"
                            value={cautionRadarVert}
                            onChange={(e) => setCautionRadarVert(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(cautionRadarVert)) ||
                                    parseFloat(cautionRadarVert) < 0 ||
                                    parseFloat(warningRadarVert) > parseFloat(cautionRadarVert))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="cautionAdsbHorz"
                            inputProps={{ "data-testid": "cautionAdsbHorz" }}
                            label="ADSB Horizontal"
                            value={cautionAdsbHorz}
                            onChange={(e) => setCautionAdsbHorz(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(cautionAdsbHorz)) ||
                                    parseFloat(cautionAdsbHorz) < 0 ||
                                    parseFloat(warningAdsbHorz) > parseFloat(cautionAdsbHorz))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="cautionAdsbVert"
                            inputProps={{ "data-testid": "cautionAdsbVert" }}
                            label="ADSB Vertical"
                            value={cautionAdsbVert}
                            onChange={(e) => setCautionAdsbVert(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(cautionAdsbVert)) ||
                                    parseFloat(cautionAdsbVert) < 0 ||
                                    parseFloat(warningAdsbVert) > parseFloat(cautionAdsbVert))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                    </Box>
                    <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr 1fr" : "1fr", gap: 2 }}>
                        <TextField
                            id="cautionUasHorz"
                            inputProps={{ "data-testid": "cautionUasHorz" }}
                            label="UAS Horizontal"
                            value={cautionUasHorz}
                            onChange={(e) => setCautionUasHorz(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(cautionUasHorz)) || parseFloat(cautionUasHorz) < 0 || parseFloat(warningUasHorz) > parseFloat(cautionUasHorz))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="cautionUasVert"
                            inputProps={{ "data-testid": "cautionUasVert" }}
                            label="UAS Vertical"
                            value={cautionUasVert}
                            onChange={(e) => setCautionUasVert(e.target.value)}
                            error={
                                snackbar &&
                                (isNaN(parseFloat(cautionUasVert)) || parseFloat(cautionUasVert) < 0 || parseFloat(warningUasVert) > parseFloat(cautionUasVert))
                            }
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                        />
                        <TextField
                            id="cautionProjection"
                            inputProps={{ "data-testid": "cautionProjection" }}
                            label="Projection"
                            value={cautionProjection}
                            onChange={(e) => handleIntegerFieldChange(e, setCautionProjection)}
                            error={snackbar && (isNaN(parseFloat(cautionProjection)) || parseFloat(cautionProjection) < 0)}
                            fullWidth
                            margin="dense"
                            sx={{ my: 0 }}
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} id="close" data-testid="close" sx={{ mr: "auto" }}>
                    Close
                </Button>
                <Button onClick={handleSave} id="save" data-testid="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
